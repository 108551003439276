import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero, Intro } from "../components/StandartPage/index"
import { Steps } from "../components/Sections/index"
import BlogCard from "../components/PostCard/PostCardGroup"

import Seo from "../components/seo"

const KurumsalPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo
      title="Bilişim Avukatı Kurumsal - Hakkımızda"
      description="Bilişim Avukatı olarak felsefemiz şudur: yardımcı olabileceğimizi düşünüyorsanız, telefonu açın ve bizi arayın."
    />
    <Hero
      title="Kurumsal"
      subtitle="Sizin için sonuç almak için buradayız. Basitçe söylemek gerekirse, felsefemiz şudur: yardımcı olabileceğimizi düşünüyorsanız, telefonu açın ve bizi arayın."
    />
    <Intro
      title="Avukat Bilişim ve Hukuk Bürosu"
      description="Avukat Bilişim ve Hukuk Bürosu, yasal tavsiye arayan işletmelere alternatif bir seçenek sunmak için 2020 yılında kuruldu.
      <br/><br/>
      Yaptığımız iş: Bilişim firmaları, sosyal medya ve fenomenlere maliyetleri olmadan bilişim tavsiyesi sunmak. Avukatlarımız, uzmanlık alanlarımızda eksiksiz bir hukuk hizmetleri yelpazesi sunmaktadır: bilgi teknolojisi hukuku, fikri mülkiyet hukuku, ticaret hukuku, iş hukuku ve şirketler hukuku. Hem çekişmeli hem de çekişmeli olmayan işlerle ilgileniyoruz."
    />
    <Steps />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default KurumsalPage

export const blogQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
